export function recruit(){
  const navLinks = document.querySelectorAll(".site-recruit nav a");
  const reclBoxes = document.querySelectorAll(".reclbox");

  navLinks.forEach(link => {
    link.addEventListener("click", function(event) {
      event.preventDefault();
      if (link.classList.contains("active")) {
        return;
      }
      
      // Remove active class from all links and boxes
      navLinks.forEach(link => link.classList.remove("active"));
      reclBoxes.forEach(box => box.classList.remove("active"));

      // Add active class to clicked link and corresponding box
      link.classList.add("active");
      const targetId = link.getAttribute("data-open");
      document.getElementById(targetId).classList.add("active");
    });
  });
}