import ScrollMagic from 'scrollmagic';
import {scrolEvents} from './default';
import {loading} from './loading';
import {index} from './index';
import {mouvers} from './mouvers';
import {recruit} from './recruit';
import {company} from './company';
import {vues} from './vues';
require("fslightbox");

document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
    vues();
    company();
    mouvers();
    recruit();
	scrolEvents(controller , scene);
    
    // LOADING 処理
    const keyName = 'visited';
    const keyValue = true;
    const app = document.getElementById('app');

    if (!sessionStorage.getItem(keyName)) {
        sessionStorage.setItem(keyName, keyValue);
        loading();
        setTimeout(function(){
			if(app){
				app.classList.add('active');
			}
        },100);
    } else {
        const intro = document.getElementById('intro');
        if(intro){
            intro.remove();
        }
		if(app){
			app.classList.add('active');
		}
        index();
    }
    

});